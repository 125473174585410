import { createSlice } from '@reduxjs/toolkit';
import { templateTable } from 'pages/ConceptOverview/constant';

const initialState = {
  dashboard: { state: {}, numRows: 20 },
  conceptMilestone: { table: templateTable, isHideInActive: true },
  campaignMilestone: { table: templateTable, isHideInActive: true },
  teamId: null,
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    initDashboard: (state) => {
      state.dashboard = null;
    },
    setDashboard: (state, { payload }) => {
      state.dashboard.state = payload;
    },
    setNumRows: (state, { payload }) => {
      state.dashboard.numRows = payload;
    },
    setTeamId: (state, { payload }) => {
      if (state.teamId != payload) state.dashboard = initialState.dashboard;
      state.teamId = payload;
    },
    setActiveMilestoneColumns: (state, { payload }) => {
      state.conceptMilestone.table = payload;
    },
    setIsHideInactive: (state, { payload }) => {
      state.conceptMilestone.isHideInActive = payload;
    },
    setActiveMilestoneColumnsCampaign: (state, { payload }) => {
      state.campaignMilestone.table = payload;
    },
    setIsHideInactiveCampaign: (state, { payload }) => {
      state.campaignMilestone.isHideInActive = payload;
    },
    resetFilters: () => initialState,
  },
});

export const {
  initDashboardState,
  setDashboard,
  setNumRows,
  setTeamId,
  setActiveMilestoneColumns,
  setIsHideInactive,
  setActiveMilestoneColumnsCampaign,
  setIsHideInactiveCampaign,
  resetFilters,
} = filters.actions;

export default filters.reducer;
